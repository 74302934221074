
import { defineComponent } from "vue";
import { api } from "@/common/api";
import { ElMessageBox, ElMessage } from "element-plus";
import BimgxImage from "@/components/bimgx-image.vue";
import PageHeader from "@/components/page-header.vue";

export default defineComponent({
    name: "ImgReportDetail",
    components: {
        "bimgx-image": BimgxImage,
        "page-header": PageHeader,
    },
    data() {
        return {
            hid: "",
            imgReport: null,
        };
    },

    async mounted() {
        this.hid = this.$route.params.hid as string;
        await this.loadImgReportDetail(this.hid);
    },

    methods: {
        async loadImgReportDetail(hid: string) {
            let tRes = await api.get("/img-report/get-detail", { params: { hid: hid } });
            this.imgReport = tRes.data;
            console.log(this.imgReport);
        },

        async onReview(status: string) {
            try {
                await ElMessageBox.confirm(`确定${status == "APPROVE" ? "通过" : "不通过"}此举报？`, "", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
            } catch (error) {
                return;
            }

            let tRes = await api.get("/img-report/review", { params: { hid: this.hid, status: status } });
            this.imgReport = tRes.data;
            ElMessage({
                message: "操作成功",
                type: 'success',
            });
        },
    },
});
