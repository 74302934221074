
import { defineComponent } from "vue";
import { api } from "@/common/api";
import router from "@/router";

export default defineComponent({
    name: "Login",
    components: {},
    data() {
        return {
            form: {
                username: "",
                password: "",
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
            },
        };
    },

    methods: {
        submit() {
            (this.$refs["form"] as any).validate(async (valid: boolean) => {
                if (valid) {
                    console.log("ok");
                    let tRes: any = await api.post("/auth/login", this.form);
                    console.log(tRes);
                    localStorage.sid = tRes["data"]["token"];

                    router.push("/");
                }
            });
        },
    },
});
