import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/home.vue";
import Login from "@/views/login.vue";
import Dashboard from "@/views/dashboard.vue";
import UserList from "@/views/user/user-list.vue";
import Config from "@/views/config/index.vue";
import GeneralConfig from "@/views/config/general-config.vue";
import AdpConfig from "@/views/config/adp-config.vue";
import BlockWord from "@/views/config/block-word.vue";
import LogInfoList from "@/views/app-log/log-info-list.vue";
import LogList from "@/views/app-log/log-list.vue";
import Bot from "@/views/bot/index.vue";
import WechatLinuxBot from "@/views/bot/wechat-linux-bot.vue";
import WechatWxmpRobot from "@/views/bot/wxmp-bot.vue";
import WeiboBot from "@/views/bot/weibo-bot.vue";
import BaiduImageSpider from "@/views/bot/baidu-image-spider.vue";
import ImgReportList from "@/views/censor/img-report-list.vue";
import ImgReportDetail from "@/views/censor/img-report-detail.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            { path: "", component: Dashboard },
            { path: "/user/user-list", component: UserList },
            { path: "/app-log", component: LogInfoList, },
            { path: "/app-log/log-info-list", component: LogInfoList },
            { path: "/app-log/log-list/:deviceId", component: LogList },
            {
                path: "/bot",
                component: Bot,
                children: [
                    { path: "", component: WechatLinuxBot },
                    { path: "wechat-linux-bot", component: WechatLinuxBot },
                    { path: "wxmp-bot", component: WechatWxmpRobot },
                    { path: "weibo-bot", component: WeiboBot },
                    { path: "baidu-image-spider", component: BaiduImageSpider },
                ],
            },
            { path: "/censor/img-report-list", component: ImgReportList },
            { path: "/censor/img-report-detail/:hid", component: ImgReportDetail },
            {
                path: "/config",
                component: Config,
                children: [
                    { path: "", component: GeneralConfig },
                    { path: "general-config", component: GeneralConfig },
                    { path: "adp-config", component: AdpConfig },
                    { path: "block-word", component: BlockWord },
                ]
            },
        ],
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from) => {
    if (localStorage.sid || to.path == "/login") {
        return true;
    } else {
        return "/login";
    }
});

export default router;
