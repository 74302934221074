import axios from "axios";
import { BimgxUrlUtil } from "./bimgx-url.util";
import {
    SERVER_EXCEPTION_CODE
} from "@/common/constant";
import router from "@/router";

const api = axios.create({
    baseURL: BimgxUrlUtil.getAdminApiServer(),
});

api.interceptors.request.use((config) => {
    if (localStorage.sid) {
        config.headers = {
            ...config.headers,
            "x-bimgx-admin-sid": localStorage.sid,
        }
    }

    return config;
});

api.interceptors.response.use((response) => {
    return response;
}, (error) => {
    // 未登录
    if (error.response.status == SERVER_EXCEPTION_CODE.FORBIDDEN) {
        localStorage.sid = "";
        router.replace("/login");
    }

    return Promise.reject(error);
});

export { api };