
import { defineComponent } from "vue";
import { api } from "@/common/api";
import { BimgxUrlUtil } from "@/common/bimgx-url.util";

export default defineComponent({
    name: "WeiboBot",
    data() {
        return {
            isLogin: false,
            qrcodeUrl: "",
        };
    },

    async mounted() {
        await this.checkLogin();
    },

    methods: {
        async checkLogin() {
            this.qrcodeUrl = "";

            let tRes = await api.get("weibo-bot/is-login");
            this.isLogin = tRes.data["isLogin"];
        },

        async login() {
            let tRes = await api.get("weibo-bot/login");
            let qrcodeUrl = tRes.data["qrcodeUrl"];
            this.qrcodeUrl = BimgxUrlUtil.getRealUrl(qrcodeUrl);
        },

        async logout() {
            let tRes = await api.get("weibo-bot/logout");
            this.isLogin = tRes.data;
        },
    },
});
