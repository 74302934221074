import urlcat from 'urlcat';

//BimgxUrlUtil
export class BimgxUrlUtil {
    public static isBimgxUrl(url = '') {
        return /^bimgx:\/\//i.test(url);
    }

    /**
     * 专有地址转换为实际地址
     */
    public static getRealUrl(url: string) {
        if (!this.isBimgxUrl(url)) {
            return url;
        }

        if (url.startsWith("bimgx://file")) {
            return url.replace("bimgx://file", this.getFileServer());
        } else if (url.startsWith("bimgx://static")) {
            return url.replace("bimgx://static", this.getStaticServer());
        } else if (url.startsWith("bimgx://u")) {
            return url.replace("bimgx://u", this.getUServer());
        } else if (url.startsWith("bimgx://ur")) {
            return url.replace("bimgx://ur", this.getUrServer());
        } else {
            return url.replace("bimgx://", this.getApiServer());
        }
    }

    public static getThumbnailUrl(url: string, long = 200) {
        if (!this.isBimgxUrl(url)) {
            return url;
        }

        return urlcat(url, {
            fileProcess: `image/thumbnail/l_${long}`
        });
    }

    public static getAdminApiServer() {
        return `${this.getProto()}api.${this.getDomain()}/admin`;
    }

    public static getApiServer() {
        return `${this.getProto()}api.${this.getDomain()}`;
    }

    public static getFileServer() {
        return `${this.getProto()}file.${this.getDomain()}`;
    }

    public static getStaticServer() {
        return `${this.getProto()}static.${this.getDomain()}`;
    }

    public static getUServer() {
        return `${this.getProto()}u.${this.getDomain()}`;
    }

    public static getUrServer() {
        return `${this.getProto()}ur.${this.getDomain()}`;
    }

    /**
     * 根据当前访问地址，获取服务域名
     */
    public static getDomain() {
        const domain = location.hostname.slice(location.hostname.indexOf(".") + 1);

        return domain;
    }

    public static getProto() {
        return location.protocol + "//";
    }
}
