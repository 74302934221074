
import { defineComponent } from "vue";
import { BimgxUrlUtil } from "@/common/bimgx-url.util";

export default defineComponent({
    name: "BimgxImage",
    props: {
        src: {
            type: String,
            default: "",
        },
        // style: {
        //     type: String,
        //     default: "",
        // },
        canNewTabView: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            cSrc: "/static/img/icon-default-img.png",
            imgLocalPath: "",
            imgFileCacheService: null,
        };
    },
    watch: {
        async src(newValue, oldValue) {
            await this.setSrc();
        }
    },
    async created() {
        await this.setSrc();
    },
    methods: {
        async setSrc() {
            if (!this.src) {
                return;
            }

            if (this.src.startsWith('bimgx://')) {
                this.cSrc = BimgxUrlUtil.getRealUrl(this.src);
            } else {
                // 其他直接显示
                this.cSrc = this.src;
            }
        },

        onClick() {
            window.open(this.cSrc);
        },
    },
});
