import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = {
  class: "left",
  style: {"display":"flex","flex-direction":"row","align-items":"center"}
}
const _hoisted_3 = { style: {"margin-left":"6px"} }
const _hoisted_4 = {
  class: "right",
  style: {"margin-left":"auto"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_i_back = _resolveComponent("el-i-back")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_divider = _resolveComponent("el-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showBack)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "back",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onGoBack && _ctx.onGoBack(...args))),
            style: {"display":"flex","flex-direction":"row","align-items":"center","cursor":"pointer"}
          }, [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_i_back)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.backWording), 1),
            _createVNode(_component_el_divider, { direction: "vertical" })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "right")
    ])
  ]))
}