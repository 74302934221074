
import { defineComponent } from "vue";
import { Monitor, User, Document, ChatDotRound, Setting, Warning, Picture } from "@element-plus/icons";
import { api } from "@/common/api";
import router from "@/router";

export default defineComponent({
    name: "Home",
    components: {
        "el-i-monitor": Monitor,
        "el-i-user": User,
        "el-i-document": Document,
        "el-i-chat-dot-round": ChatDotRound,
        "el-i-setting": Setting,
        "el-i-warning": Warning,
        "el-i-picture": Picture,
    },
    methods: {
        async onCommand() {
            let tRes = await api.get("/auth/logout");
            localStorage.removeItem("sid");
            router.push("/login");
        },
    }
});
