import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
// import "@/assets/style/theme.scss";
import "@/assets/style/base.scss";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Unicon from 'vue-unicons';
import { uniCheckCircle, uniInfoCircle } from 'vue-unicons/dist/icons';

Unicon.add([uniCheckCircle, uniInfoCircle]);

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(Unicon);

app.mount("#app");
