
import { defineComponent } from "vue";
import { api } from "@/common/api";

export default defineComponent({
    name: "Dashboard",
    components: {},

    data() {
        return {
            index: {
                userAmount: 0,
                dayNewUser: 0,
            }
        };
    },

    async mounted() {
        await this.getIndex();
    },

    methods: {
        async getIndex() {
            let tRes = await api.get("/dashboard/get-index");
            this.index = tRes.data;
        }
    }
});
