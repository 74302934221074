
import { defineComponent } from "vue";
import { api } from "@/common/api";
import { ElMessage, ElMessageBox } from "element-plus";

export default defineComponent({
    name: "AdpConfig",

    data() {
        return {
            adpConfigList: [],
            pagination: {
                page: 1,
                pageSize: 10,
                pageCount: 0,
                withCount: true,
            },
            showDlg: false,
            editDlg: false,
            form: {
                adpKey: "",
                name: "",
                adpid: "",
                showRatio: 100,
            },
            rules: {
                adpKey: [{ required: true, message: "请输入广告位key", trigger: "blur" }],
                name: [{ required: true, message: "请输入广告位名字", trigger: "blur" }],
                adpid: [{ required: true, message: "请输入adpid", trigger: "blur" }],
                showRatio: [
                    { type: "number", required: true, message: "请输入显示概率", trigger: "blur" },
                    { type: "number", min: 0, max: 100, message: "显示概率需要在0~100之间", trigger: "blur" },
                ],
            },
        };
    },

    watch: {
        "pagination.page": async function (newValue, oldValue) {
            if (newValue != oldValue) {
                await this.loadAdpConfigList();
            }
        },
    },

    async mounted() {
        await this.loadAdpConfigList();
    },

    methods: {
        async loadAdpConfigList() {
            let tRes = await api.get("/ad/get-adp-config-list", { params: { ...this.pagination } });
            this.adpConfigList = tRes.data["list"];
            this.pagination = tRes.data["pagination"];
        },

        onAddAdpConfig() {
            this.editDlg = false;
            this.form = {
                adpKey: "",
                name: "",
                adpid: "",
                showRatio: 100,
            };
            this.showDlg = true;
        },

        onEditAdpConfig(adpConfig: object) {
            this.editDlg = true;
            Object.assign(this.form, adpConfig);
            this.showDlg = true;
        },

        onDlgConfirm() {
            (this.$refs.form as any).validate(async (valid, fields) => {
                if (valid) {
                    console.log("submit!");

                    this.showDlg = false;
                    let apiPath = this.editDlg ? "/ad/edit-adp-config" : "/ad/add-adp-config";
                    let res = await api.post(apiPath, this.form);
                    // ElMessage({
                    //     message: "操作成功",
                    //     type: "success",
                    // });
                    await this.loadAdpConfigList();
                } else {
                    console.log("error submit!", fields);
                }
            });
        },

        async onDelAdpConfig(hid: string) {
            try {
                await ElMessageBox.confirm("确定删除此广告位设置？", "Warning", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
            } catch (error) {
                return;
            }

            let res = await api.get("/ad/del-adp-config", { params: { hid: hid } });
            await this.loadAdpConfigList();
            // ElMessage({
            //     message: "删除成功",
            //     type: "success",
            // });
        },
    },
});
