import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _ctx.cSrc,
    style: _normalizeStyle(_ctx.canNewTabView ? 'cursor: pointer;' : ''),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.canNewTabView && _ctx.onClick()))
  }, null, 12, _hoisted_1))
}