
import { defineComponent } from "vue";
import { api } from "@/common/api";
import * as QRCode from "qrcode";
import { ElMessage } from "element-plus";

export default defineComponent({
    name: "BaiduImageSpider",
    data() {
        return {
            spiderList: [],
            pagination: {
                page: 1,
                pageSize: 10,
                pageCount: 0,
                withCount: true,
            },
            showQrcodeDlg: false,
        };
    },

    watch: {
        "pagination.page": async function (newValue, oldValue) {
            if (newValue != oldValue) {
                await this.loadList();
            }
        },
    },

    async mounted() {
        await this.loadList();
    },

    methods: {
        async loadList() {
            let tRes = await api.get("baidu-image-cluster/get-spider-list", { params: { ...this.pagination } });

            this.spiderList = tRes.data["list"];
            this.pagination = tRes.data["pagination"];
        },

        async verify(id: number) {
            this.showQrcodeDlg = true;
            let tRes = await api.get("baidu-image-cluster/verify", { params: { id: id } });
            let qrcodeContent = tRes.data.qrcodeContent;

            QRCode.toCanvas(document.getElementById("qrcode_canvas"), qrcodeContent, function (error) {
                if (error) {
                    ElMessage({
                        message: "二维码显示失败",
                        type: "error",
                    });
                }
            });
        },
    },
});
