
import { defineComponent } from "vue";
import { api } from "@/common/api";
import { BimgxUrlUtil } from "@/common/bimgx-url.util";

export default defineComponent({
    name: "LogList",
    data() {
        return {
            deviceId: "",
            list: [],
            pagination: {
                page: 1,
                pageSize: 10,
                pageCount: 0,
                withCount: true,
            },
        };
    },

    watch: {
        "pagination.page": async function (newValue, oldValue) {
            if (newValue != oldValue) {
                await this.loadList();
            }
        },
    },

    async mounted() {
        this.deviceId = this.$route.params.deviceId as string;
        await this.loadList();
    },

    methods: {
        onGoBack() {
            this.$router.back();
        },

        async loadList() {
            let tRes = await api.get("/app-log/get-log-list", { params: { deviceId: this.deviceId, ...this.pagination } });

            this.list = tRes.data["list"];
            this.pagination = tRes.data["pagination"];
        },

        async downloadLog(url: string) {
            let realUrl = BimgxUrlUtil.getRealUrl(url);
            window.open(realUrl, "blank");
        },
    },
});
