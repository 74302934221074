
import { defineComponent } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { api } from "@/common/api";

export default defineComponent({
    name: "UserList",
    data() {
        return {
            list: [],
            pagination: {
                page: 1,
                pageSize: 10,
                pageCount: 0,
                withCount: true,
            },
        };
    },

    watch: {
        "pagination.page": async function (newValue, oldValue) {
            if (newValue != oldValue) {
                await this.loadList();
            }
        },
    },

    async mounted() {
        await this.loadList();
    },

    methods: {
        async loadList() {
            let tRes = await api.get("/user/get-user-list", { params: { ...this.pagination } });
            this.list = tRes.data["list"];
            this.pagination = tRes.data["pagination"];
        },

        async onDeleteUser(hid: string) {
            try {
                await ElMessageBox.confirm(`确定删除此用户？`, "", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
            } catch (error) {
                return;
            }
            await api.get("/user/delete", { params: { userHid: hid } });
            ElMessage({ message: "操作成功", type: 'success', });
            await this.loadList();
        }
    },
});
