
import { defineComponent } from "vue";
import { api } from "@/common/api";
import BimgxImage from "@/components/bimgx-image.vue";
import PageHeader from "@/components/page-header.vue";

export default defineComponent({
    name: "ImgReportList",
    components: {
        "bimgx-image": BimgxImage,
        "page-header": PageHeader,
    },
    data() {
        return {
            list: [],
            pagination: {
                page: 1,
                pageSize: 10,
                pageCount: 0,
                withCount: true,
            },
        };
    },
    watch: {
        "pagination.page": async function (newValue, oldValue) {
            if (newValue != oldValue) {
                await this.loadList();
            }
        },
    },

    async mounted() {
        await this.loadList();
    },

    methods: {
        // onViewLogList(deviceId: string) {
        //     this.$router.push("/censor/img-report-detail/" + deviceId);
        // },
        async loadList() {
            let tRes = await api.get("/img-report/get-list", { params: { ...this.pagination } });
            this.list = tRes.data["list"];
            this.pagination = tRes.data["pagination"];
        },
    }
});
