
import { defineComponent } from "vue";
import { api } from "@/common/api";
import { ElMessage } from "element-plus";
import * as QRCode from "qrcode";

export default defineComponent({
    name: "WechatLinuxBot",
    data() {
        return {
            botStatus: {
                serverConnected: false,
                wechatProcessStarted: false,
                wechatLoggedin: false,
            },
            isLoggingIn: false, // 是否在登录操作，用于显示登录二维码
        };
    },

    async mounted() {
        await this.getStatus();
    },

    methods: {
        async getStatus() {
            let tRes = await api.get("wechat-linux-bot/get-bot-status");
            this.botStatus = tRes.data;
            if (this.botStatus?.["wechatLoggedin"]) {
                this.isLoggingIn = false;
            }
        },

        async connect() {
            let tRes = await api.get("wechat-linux-bot/connect");
            this.botStatus = tRes.data;
        },

        async startWechatProcess() {
            let tRes = await api.get("wechat-linux-bot/start-wechat-process");
            this.botStatus = tRes.data;
        },

        async restartWechatProcess() {
            let tRes = await api.get("wechat-linux-bot/restart-wechat-process");
            this.botStatus = tRes.data;
        },

        async login() {
            this.isLoggingIn = true;
            await this.getLoginQrcode();
        },

        async getLoginQrcode() {
            let tRes = await api.get("wechat-linux-bot/get-login-qrcode");
            let qrcodeInfo = tRes.data;

            QRCode.toCanvas(document.getElementById("qrcode_canvas"), qrcodeInfo["content"], function (error) {
                if (error) {
                    ElMessage({
                        message: "二维码显示失败",
                        type: "error",
                    });
                }
            });
        },
    },
});
