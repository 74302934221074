
import { defineComponent } from "vue";
import { api } from "@/common/api";
import { ElMessage, ElMessageBox } from "element-plus";

export default defineComponent({
    name: "BlockWord",

    data() {
        return {
            blockWordList: [],
            pagination: {
                page: 1,
                pageSize: 10,
                pageCount: 0,
                withCount: true,
            },
            showDlg: false,
            editDlg: false,
            form: {
                hid: "",
                word: "",
            },
            rules: {
                name: [{ required: true, message: "请输入屏蔽词", trigger: "blur" }],
            },
        };
    },

    watch: {
        "pagination.page": async function (newValue, oldValue) {
            if (newValue != oldValue) {
                await this.loadblockWordList();
            }
        },
    },

    async mounted() {
        await this.loadblockWordList();
    },

    methods: {
        async loadblockWordList() {
            let tRes = await api.get("/block-word/get-list", { params: { ...this.pagination } });
            this.blockWordList = tRes.data["list"];
            this.pagination = tRes.data["pagination"];
        },

        onAddBlockWord() {
            this.editDlg = false;
            this.form = {
                hid: "",
                word: "",
            };
            this.showDlg = true;
        },

        onEditBlockWord(blockWord: object) {
            this.editDlg = true;
            Object.assign(this.form, blockWord);
            this.showDlg = true;
        },

        onDlgConfirm() {
            (this.$refs.form as any).validate(async (valid, fields) => {
                if (valid) {
                    console.log("submit!");

                    this.showDlg = false;
                    let apiPath = this.editDlg ? "/block-word/edit" : "/block-word/add";
                    let res = await api.post(apiPath, this.form);
                    // ElMessage({
                    //     message: "操作成功",
                    //     type: "success",
                    // });
                    await this.loadblockWordList();
                } else {
                    console.log("error submit!", fields);
                }
            });
        },

        async onDelBlockWord(hid: string) {
            try {
                await ElMessageBox.confirm("确定删除此屏蔽词？", "Warning", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
            } catch (error) {
                return;
            }

            let res = await api.get("/block-word/del", { params: { hid: hid } });
            await this.loadblockWordList();
            // ElMessage({
            //     message: "删除成功",
            //     type: "success",
            // });
        },
    },
});
