
import { defineComponent } from "vue";
import { api } from "@/common/api";
import { ElMessageBox } from "element-plus";

export default defineComponent({
    name: "LogInfoList",
    data() {
        return {
            list: [],
            pagination: {
                page: 1,
                pageSize: 10,
                pageCount: 0,
                withCount: true,
            },
        };
    },

    watch: {
        "pagination.page": async function (newValue, oldValue) {
            if (newValue != oldValue) {
                await this.loadInfoList();
            }
        },
    },

    async mounted() {
        await this.loadInfoList();
    },

    methods: {
        onViewLogList(deviceId: string) {
            this.$router.push("/app-log/log-list/" + deviceId);
        },

        async loadInfoList() {
            let tRes = await api.get("/app-log/get-log-info-list", { params: { ...this.pagination } });
            this.list = tRes.data["list"];
            this.pagination = tRes.data["pagination"];
        },

        async onToggleLogStatus(row: any) {
            try {
                await ElMessageBox.confirm(`确定${row.log ? "关闭" : "开启"}吗？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true,
                });
            } catch (e) {
                return;
            }

            let tRes = await api.get("/app-log/set-log-status", { params: { deviceId: row.deviceId, status: !row.log } });
            row.log = tRes.data["status"];
        },
    },
});
