<template>
    <router-view></router-view>
</template>

<style lang="scss">
*:not(img), *:before, *:after
{ box-sizing: inherit; }

html,
body {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    margin: 0px;
}

#app {
    height: 100%;
    width: 100%;
}
</style>
