
import { defineComponent } from "vue";
import { Back } from "@element-plus/icons";

export default defineComponent({
    name: "PageHeader",
    components: {
        "el-i-back": Back,
    },
    props: {
        showBack: {
            type: Boolean,
            default: true,
        },
        backWording: {
            type: String,
            default: "返回",
        },
        title: {
            type: String,
            default: ""
        }
    },
    data() {
        return {};
    },
    methods: {
        onGoBack() {
            this.$router.back();
        },
    }
});
